<template>
    <footer class="footer">
        <div class="footer-icons">
            <a href="https://discord.gg/q4h4hsXWbh">
                <img :src="DiscordSvg" alt="Discord">
            </a>
            <a href="https://vk.com/solarmp">
                <img :src="VkSVG" alt="VK">
            </a>
            <a href="https://t.me/solar_cybermp">
                <img :src="TgSVG" alt="Telegram">
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            DiscordSvg: require('../assets/discord-mark-white.svg'),
            VkSVG: require('../assets/VK Logo White.svg'),
            TgSVG: require('../assets/telegram_svg.svg'),
        }
    },
}
</script>

<style>
.footer {
    position: absolute;
    bottom: 20px; /* Adjust this value to move the footer higher or lower */
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.footer-icons {
    display: flex;
    justify-content: center;
}

.footer-icons a {
    margin: 0 20px; /* Adjusted spacing between icons */
}

.footer-icons a:hover img {
    transform: scale(1.2); /* Увеличивает иконку */
    filter: drop-shadow(0 0 20px #FF2D00); /* Создает мягкую тень вокруг иконки */
    transition: transform 0.3s ease, filter 0.3s ease; /* Плавное изменение */
}

.footer-icons img {
    width: 50px; /* Increased width */
    height: 50px; /* Increased height */
}
</style>
