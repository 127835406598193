<template>
    <div class="promo-solar">
        <div class="logo-container">
            <img width="600" height="600" src="@/assets/logo.svg" alt="Лого" /> <!-- Путь к вашему SVG лого -->
        </div>
        <div class="loading-dots">
            <div></div><div></div><div></div>
        </div>
        <PromoFooter />
    </div>
</template>

<script>
import PromoFooter from './PromoFooter.vue'

export default {
    name: 'PromoSolar',
    components: {
        PromoFooter
    }
}
</script>

<style>
@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

html, body, #app, .promo-solar {
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevents scrolling */
    height: 100vh; /* Ensures full viewport height */
    width: 100vw; /* Ensures full viewport width */
    background-color: black; /* Черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Для позиционирования точек */
}

.logo-container {
    z-index: 2; /* Чтобы лого было над точками */
}

.loading-dots {
    position: absolute;
    margin-top: 10%;
    width: 100%;
    text-align: center;
}

.loading-dots div {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 3px;
    background-color: white;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
}

.loading-dots div:nth-child(1) { animation-delay: -0.32s; }
.loading-dots div:nth-child(2) { animation-delay: -0.16s; }
.loading-dots div:nth-child(3) { animation-delay: 0; }

.promo-solar {
    background-image: none; /* Убираем предыдущий фон */
    position: relative; /* Allows absolute positioning of footer */
}
</style>