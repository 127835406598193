<template>
  <PromoSolar />
</template>

<script>
import PromoSolar from './components/PromoSolar.vue'

export default {
  name: 'App',
  components: {
    PromoSolar
  }
}
</script>

<style>
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden; /* Prevents scrolling */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
